app.controller('RecipientsController', ['$scope', '$log', '$mdDialog', '$http', function ($scope, $log, $mdDialog, $http) {
    $log.debug('Loading RecipientsController');

    setupRecipientUploader($scope, $mdDialog);

    $scope.addRecipient = function () {
        window.location.href = '/account/recipient';
    };

    $scope.editRecipient = function (zoneId) {
        window.location.href = '/account/recipient/' + zoneId;
    };

    function handleSubmission(method, url, fn) {
        var error = $mdDialog.alert()
            .title('This is embarrassing!')
            .htmlContent('Sorry! An error occurred. Please try again in a few minutes.<br/>If the error continues, please contact support.')
            .ariaLabel('error occurred')
            .ok('OK');

        $http({
            method: method,
            url: url
        }).then(function successCallback(response) {
            if (fn && typeof fn === 'function') {
                fn.call(null);
            } else if (fn === true) {
                document.location.reload(true);
            }
        }, function errorCallback(response) {
            $mdDialog.show(error);
        });
    }

    $scope.deleteRecipient = function (zoneId) {
        var confirm = $mdDialog.confirm()
            .title('Delete Recipient?')
            .htmlContent('This will permanently delete this recipient from your account. Are you sure?')
            .ariaLabel('delete recipient')
            .ok('Yes, delete this recipient')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
            handleSubmission('DELETE', '/account/recipient/' + zoneId, true);
        });
    };

    $scope.submitRecipient = function () {
        if ($scope.recipientForm.$pristine || !$scope.recipientForm.$valid) {
            return;
        }

        for (var i in $scope.recipient.address) {
            if ($scope.recipient.address.hasOwnProperty(i)) {
                $hiddenInput = $('<input>')
                    .attr('type', 'hidden')
                    .attr('name', 'addressType[' + i + ']')
                    .attr('value', $scope.recipient.addressType[i]);
                $('[name="recipientForm"]').append($hiddenInput);
            }
        }

        var j = 0;
        for (var zone_id in $scope.recipient.zones) {
            if ($scope.recipient.zones.hasOwnProperty(zone_id)) {
                if ($scope.recipient.zones[zone_id]) {

                    var subgroups = [];

                    for(var subgroup in $scope.recipient.zones[zone_id]) {
                        if ($scope.recipient.zones[zone_id][subgroup]) {
                            subgroups.push(subgroup);
                        }
                    }

                    $hiddenInput = $('<input>')
                        .attr('type', 'hidden')
                        .attr('name', 'zone[' + (j++) + ']')
                        .attr('value', [ zone_id ].concat(subgroups).join(","));
                    $('[name="recipientForm"]').append($hiddenInput);
                }
            }
        }

        $scope.recipientForm.$submitted = true;
        $('[name="recipientForm"]').submit();
    };

    $scope.addAddress = function () {
        $($('.empty-address')[0]).removeClass('empty-address').show();
    };

    $scope.deleteAddress = function (id) {
        $address = $('#address-' + id);
        $address.addClass('empty-address').hide();
        $address.find('input,select').val('');
        $scope.recipient.address[id] = "";
        $scope.recipient.addressType[id] = "0";
        $scope.recipientForm.$pristine = false;
        $address.insertAfter($('#addresses-table tr:last-child'));
    };
}]);
