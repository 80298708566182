/**
 * The global Angular application.
 * @var app
 */
var app = angular.module('TracerManage', ['ngMaterial', 'ngMessages', 'ngSanitize']);

// Configure the application theming for Angular Material.
app.config(function ($mdThemingProvider) {

    $mdThemingProvider.theme('default')
        .primaryPalette('blue-grey')
        .accentPalette('light-blue');
});
