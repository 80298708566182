app.controller('AuthController', ['$scope', '$log', function ($scope, $log) {
    $log.debug('Loading AuthController');

    $scope.deviceIdRegex = /^[0-9a-fA-F]{20}$/

    $scope.registration = {
        name: ''
    };

    $scope.submit = function() {
        if ($scope.authForm.$pristine || !$scope.authForm.$valid) {
            return;
        }
        $('[name="authForm"]').submit();
    };

    Mousetrap($('[name="authForm"]')[0]).bind('enter', function() {
        $scope.submit();
    });
}]);
