app.controller('ZonesController', ['$scope', '$log', '$mdDialog', '$http', function ($scope, $log, $mdDialog, $http) {
    $log.debug('Loading ZonesController');

    $scope.addZone = function () {
        window.location.href = '/account/zone';
    };

    $scope.editZone = function (zoneId) {
        window.location.href = '/account/zone/' + zoneId;
    };

    function handleSubmission(method, url, fn) {
        var error = $mdDialog.alert()
            .title('This is embarrassing!')
            .htmlContent('Sorry! An error occurred. Please try again in a few minutes.<br/>If the error continues, please contact support.')
            .ariaLabel('error occurred')
            .ok('OK');

        $http({
            method: method,
            url: url
        }).then(function successCallback(response) {
            if (fn && typeof fn === 'function') {
                fn.call(null);
            } else if (fn === true) {
                document.location.reload(true);
            }
        }, function errorCallback(response) {
            $mdDialog.show(error);
        });
    }

    $scope.deleteZone = function (zoneId) {
        var confirm = $mdDialog.confirm()
            .title('Delete Zone?')
            .htmlContent('This will permanently delete this zone from your account. Are you sure?')
            .ariaLabel('delete zone')
            .ok('Yes, delete this zone')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
            handleSubmission('DELETE', '/account/zone/' + zoneId, true);
        });
    };

    $scope.submitZone = function () {
        if ($scope.zoneForm.$pristine || !$scope.zoneForm.$valid) {
            return;
        }
        $scope.zoneForm.$submitted = true;
        $('[name="zoneForm"]').submit();
    };
}]);
