app.controller("FloorPlanController", [
  "$scope",
  "$log",
  "$mdDialog",
  "$http",
  function($scope, $log, $mdDialog, $http) {
    $log.debug("Loading FloorPlanController");

    function getQueryStringValue(key) {
      return decodeURIComponent(
        window.location.search.replace(
          new RegExp(
            "^(?:.*[&\\?]" +
              encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
              "(?:\\=([^&]*))?)?.*$",
            "i"
          ),
          "$1"
        )
      );
    }

    var dz = new Dropzone("#dropzone", {
      url: "/account/monitor/images",
      acceptedFiles: "image/*"
    });

    var alert = new Audio("/sounds/alarm.mp3");
    alert.loop = true;

    dz.on("sending", function() {
      $scope.uploading = true;
      $scope.uploadingForFloor = $scope.currentFloor;
      $scope.$apply();
    });

    dz.on("success", function(e, r) {
      var idx = _.findIndex($scope.data.floors, function(f) {
        return f.id === $scope.uploadingForFloor;
      });
      $scope.uploading = false;
      $scope.data.floors[idx].image = r.image;
      $scope.$apply();
    });

    var savedAwkAlertIds = localStorage.getItem("ackAlertIds");
    var savedDelAlertIds = localStorage.getItem("delAlertIds");

    $scope.alerts = [];
    $scope.ackAlertIds = savedAwkAlertIds ? JSON.parse(savedAwkAlertIds) : [];
    $scope.delAlertIds = savedDelAlertIds ? JSON.parse(savedDelAlertIds) : [];

    var lastAlertGet;
    function getAlerts() {
      var url = [
        __UPDATE_URL__,
        "?",
        "account=",
        _.get(__DEVICES__, "[0].user_id")
      ];
      if (lastAlertGet) {
        url.push("since=" + lastAlertGet);
      }
      $http.get(url.join("")).then(function(r) {
        var updatedAlerts = _.filter(
          _.uniqBy($scope.alerts.concat(r.data), function(a) {
            return a.id;
          })
        );

        setTimeout(function() {
          var activeAlert = $(".alert-list.has-unackd-alert")[0];
          if (activeAlert && activeAlert.scrollIntoView) {
            activeAlert.scrollIntoView();
          }
        }, 300);

        $scope.alerts = updatedAlerts;
        updateAlertSound();
      });
      lastAlertGet = Math.round(Date.now() / 1000);
    }

    getAlerts();
    setInterval(getAlerts, 5000);

    function updateAlertSound() {
      var alertCount = _.filter($scope.alerts, function(a) {
        // Count the device if it's not acknowledged and also not deleted.
        return (
          $scope.ackAlertIds.indexOf(a.id) === -1 &&
          $scope.delAlertIds.indexOf(a.id) === -1 &&
          _.findIndex($scope.data.markers, function(marker) {
            return marker.id === a.device_id;
          }) !== -1
        );
      }).length;

      if (alertCount > 0) {
        alert.play();
      } else {
        alert.pause();
      }
    }

    function handleSubmission(url, data, fn) {
      var error = $mdDialog
        .alert()
        .title("This is embarrassing!")
        .htmlContent(
          "Sorry! An error occurred. Please try again in a few minutes.<br/>If the error continues, please contact support."
        )
        .ariaLabel("error occurred")
        .ok("OK");

      $http.post(url, data).then(
        function successCallback(response) {
          if (fn && typeof fn === "function") {
            fn.call(null);
          } else if (fn === true) {
            document.location.reload(true);
          }
        },
        function errorCallback(response) {
          $mdDialog.show(error);
        }
      );
    }

    function randomFloorId() {
      return Math.round(Math.random() * 1e8);
    }

    var floors = _.get(__PLAN__, "floors", []);
    var devices = __DEVICES__ || [];

    $scope.data = {
      devices: devices,
      markers: _.filter(_.get(__PLAN__, "markers", []), function(m) {
        return _.find(devices, function(d) {
          return d.id === m.id;
        });
      }),
      floors: _.get(__PLAN__, "floors", [])
    };

    var zoomToDevice = getQueryStringValue("device");

    if (zoomToDevice) {
      var marker = _.find(__PLAN__.markers, function(marker) {
        return marker.id === zoomToDevice && marker.floor;
      });
      var floor = _.find(floors, function(floor) {
        return floor.id === marker.floor;
      });
      $scope.currentFloor = floor ? floor.id : null;
    }

    if (!$scope.currentFloor) {
      $scope.currentFloor = floors[0] ? floors[0].id : null;
    }

    updateAlertSound();

    function makeDevicesDraggable() {
      $(".device-marker.draggable").draggable({
        stop: function(evt, ui) {
          var deviceId = evt.target.dataset.deviceId;
          var idx = _.findIndex($scope.data.markers, function(m) {
            return m.id === deviceId && m.floor === $scope.currentFloor;
          });
          const height = $(".floor-plan-image").height();
          const width = $(".floor-plan-image").width();
          $scope.data.markers[idx].left = ui.position.left / width;
          $scope.data.markers[idx].top = ui.position.top / height;
        }
      });
    }

    function destroyDraggableDevices() {
      $(".device-marker.draggable").draggable("destroy");
    }

    $scope.openMenu = function($mdOpenMenu, ev) {
      originatorEv = ev;
      $mdOpenMenu(ev);
    };

    $scope.edit = function() {
      $scope.editing = true;
      $(".monitor").addClass("editing");
      makeDevicesDraggable();
    };

    $scope.save = function() {
      $scope.editing = false;
      $(".monitor").removeClass("editing");
      destroyDraggableDevices();

      handleSubmission(
        "/account/monitor",
        angular.toJson(_.omit($scope.data, "devices"))
      );
    };

    $scope.editingFloors = [];

    $scope.editFloorName = function(id) {
      $scope.editingFloors = $scope.editingFloors.concat(id);
      setTimeout(function() {
        $(".floor-" + id + " input").focus();
      }, 100);
    };

    $scope.getDeviceAlerts = function(id) {
      var t = _.filter($scope.alerts, function(a) {
        return (
          a.device_id.toLowerCase() === id.toLowerCase() &&
          $scope.delAlertIds.indexOf(a.id) === -1
        );
      }).map(function(a) {
        var d = new Date(a.alert_time * 1000);
        a.alert_type = _.startCase(a.alert_type);
        a.formatted_time =
          _.padStart(d.getUTCHours(), 2, '0')
          + ':'
          + _.padStart(d.getUTCMinutes(), 2, '0')
          + ':'
          + _.padStart(d.getUTCSeconds(), 2, '0');
        a.ackd = $scope.ackAlertIds.indexOf(a.id) !== -1;
        return a;
      });
      return t;
    };

    $scope.getActiveDeviceAlerts = function(id) {
      return $scope.getDeviceAlerts(id).filter(a => !a.ackd);
    };

    $scope.deviceHasUnAckdAlerts = function(id) {
      return (
        $scope.getDeviceAlerts(id).filter(function(a) {
          return !a.ackd;
        }).length > 0
      );
    };

    $scope.deviceHasAlert = function(id) {
      return $scope.getDeviceAlerts(id).length > 0;
    };

    $scope.deviceHasActiveAlert = function(id) {
      return $scope.getActiveDeviceAlerts(id).length > 0;
    };

    $scope.getMarkerClass = function(id) {
      var cls = [];
      if ($scope.deviceHasUnAckdAlerts(id)) {
        cls.push("alert");
      } else if ($scope.deviceHasAlert(id)) {
        cls.push("ackd-alert");
      }
      return cls.join(" ");
    };

    $scope.getFloorPlanClass = function() {
      if ($scope.currentFloorHasImage()) return "has-image";
      return "";
    };

    $scope.saveFloorName = function(id) {
      var idx = _.findIndex($scope.data.floors, function(f) {
        return f.id === id;
      });
      $scope.editingFloors = _.without($scope.editingFloors, id);
      $scope.data.floors[idx].name = $(".floor-" + id + " input").val();
    };

    $scope.isEditingFloorName = function(id) {
      return $scope.editingFloors.indexOf(id) !== -1;
    };

    $scope.floorHasAlerts = function(id) {
      return $scope.getFloorAlertCount(id) > 0;
    };

    $scope.floorClassName = function(id) {
      var cls = ["floor-" + id];
      var hasAlerts = $scope.floorHasAlerts(id);
      if ($scope.isEditingFloorName(id)) {
        cls.push("editing");
      }
      if (hasAlerts) {
        cls.push("has-alerts");
      }
      if (id === $scope.currentFloor) {
        cls.push("current-floor");
      }
      return cls.join(" ");
    };

    $scope.createNewFloor = function() {
      var id = randomFloorId();
      $scope.data.floors.push({
        id: id,
        name: "New Floor",
        image: null
      });
      $scope.switchFloors(id);
    };

    $scope.deleteFloor = function(floor) {
      var confirm = $mdDialog
        .confirm()
        .title("Delete Floor Plan?")
        .htmlContent(
          'Are you sure you want to delete the floor "' + floor.name + '"?'
        )
        .ok("Delete")
        .cancel("Cancel");
      $mdDialog
        .show(confirm)
        .then(function() {
          $scope.data.floors = _.without($scope.data.floors, floor);
          $scope.data.markers = _.filter($scope.data.markers, function(marker) {
            return marker.floor !== floor.id;
          });
          if (
            $scope.data.floors.length > 0 &&
            $scope.currentFloor === floor.id
          ) {
            $scope.switchFloors($scope.data.floors[0].id);
          }
        })
        .catch(function() {
          /* do nothing */
        });
    };

    function deviceOnCurrentFloor(id) {
      return _.find($scope.data.markers, function(m) {
        return m.id === id && m.floor === $scope.currentFloor;
      });
    }

    $scope.deviceClass = function(id) {
      const cls = [];
      if (deviceOnCurrentFloor(id)) {
        cls.push("unavailable");
      }
      return cls.join(" ");
    };

    $scope.deviceTitle = function(id) {
      if (deviceOnCurrentFloor(id)) {
        return "Device already added to this floor plan.";
      }
      return "Add device to floor plan.";
    };

    $scope.getMarkerLabel = function(id) {
      const device = _.find($scope.data.devices, function(d) {
        return d.id === id;
      });
      if (device) {
        return device.location;
      }
    };

    $scope.addMarker = function(id) {
      if (!deviceOnCurrentFloor(id)) {
        $scope.data.markers.push({
          id: id,
          left: 0.5,
          top: 0.5,
          floor: $scope.currentFloor
        });
        setTimeout(function() {
          makeDevicesDraggable();
        }, 500);
      }
    };

    $scope.getCurrentFloor = function() {
      return _.find($scope.data.floors, function(floor) {
        return floor.id === $scope.currentFloor;
      });
    };

    $scope.currentFloorHasImage = function() {
      const f = $scope.getCurrentFloor();
      return !!(f && f.image);
    };

    $scope.hasFloors = function() {
      return $scope.data.floors.length > 0;
    };

    $scope.getCurrentFloorMarkers = function() {
      return _.filter($scope.data.markers, function(m) {
        return m.floor === $scope.currentFloor;
      });
    };

    $scope.switchFloors = function(id) {
      $scope.currentFloor = id;
      if ($scope.editing) {
        setTimeout(function() {
          makeDevicesDraggable();
        }, 500);
      }
    };

    $scope.ackAlert = function(alertId) {
      $scope.ackAlertIds = _.uniq($scope.ackAlertIds.concat([alertId]));
      localStorage.setItem("ackAlertIds", JSON.stringify($scope.ackAlertIds));
      updateAlertSound();
    };

    $scope.delAlert = function(alertId) {
      $scope.ackAlert(alertId);
      $scope.delAlertIds = _.uniq($scope.delAlertIds.concat([alertId]));
      localStorage.setItem("delAlertIds", JSON.stringify($scope.delAlertIds));
      updateAlertSound();
    };

    $scope.clearAckAlerts = function() {
      $scope.ackAlertIds = [];
      // $scope.delAlertIds = [];
      localStorage.setItem("ackAlertIds", JSON.stringify([]));
      // localStorage.setItem("delAlertIds", JSON.stringify([]));
      updateAlertSound();
    };

    $scope.getFloorAlertCount = function(id) {
      var devicesOnFloor = _.filter($scope.data.markers, function(m) {
        return m.floor === id;
      }).map(function(m) {
        return m.id;
      });
      return _.flatten(devicesOnFloor.map($scope.getActiveDeviceAlerts)).length;
    };

    $scope.removeMarker = function(floorId, markerId) {
      $scope.data.markers = _.filter($scope.data.markers, function(m) {
        return m.id !== markerId || m.floor !== floorId;
      });
      updateAlertSound();
    };
  }
]);
