app.controller('AdminAgreementController', ['$scope', '$log', '$mdDialog', '$http', function ($scope, $log, $mdDialog, $http) {
    $log.debug('Loading AdminAgreementController');

    function showEditCreateUserDialog() {
        $mdDialog.show({
            clickOutsideToClose: true,
            scope: $scope,        // use parent scope in template
            preserveScope: true,  // do not forget this if use parent scope
            // Since GreetingController is instantiated with ControllerAs syntax
            // AND we are passing the parent '$scope' to the dialog, we MUST
            // use 'vm.<xxx>' in the template markup
            template: ($('#user-dialog')[0]).innerHTML,
            controller: function DialogController($scope, $mdDialog) {
                $scope.closeDialog = function() {
                    $mdDialog.hide();
                }
            }
        });
    }

    $scope.createAgreement= function() {
        window.location.href = '/admin/agreement'
    };

    $scope.editAgreement = function(agreementId) {
        window.location.href = '/admin/agreement/' + agreementId + '?back=' + encodeURIComponent(window.location.href);
    };

    function handleSubmission(method, url, fn) {
        var error = $mdDialog.alert()
            .title('This is embarrassing!')
            .htmlContent('Sorry! An error occurred. Please try again in a few minutes.<br/>If the error continues, please contact support.')
            .ariaLabel('error occurred')
            .ok('OK');

        $http({
            method: method,
            url: url
        }).then(function successCallback(response) {
            if (fn && typeof fn === 'function') {
                fn.call(null);
            } else if (fn === true) {
                document.location.reload(true);
            }
        }, function errorCallback(response) {
            $mdDialog.show(error);
        });
    }

    $scope.deleteAgreement = function(agreement) {
        var confirm = $mdDialog.confirm()
            .title('Delete agreement?')
            .htmlContent('This will permanently delete the <strong>' + agreement.title + '</strong> agreement. Are you sure?')
            .ariaLabel('delete agreement')
            .ok('Yes, delete forever')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            handleSubmission('DELETE', '/admin/agreement/' + agreement.id, true);
        });
    };

    $scope.submitAgreement = function() {
        if ($scope.agreementForm.$pristine || !$scope.agreementForm.$valid) {
            return;
        }
        $('[name="model_definition"]').val($scope.agreement.model_definition);
        $('[name="id"]').val($('[name="id"]').val().trim());
        $scope.agreementForm.$submitted = true;
        $('[name="agreementForm"]').submit();
    };

    Mousetrap($('[name="agreementForm"]')[0]).bind('enter', function() {
        $scope.submit();
    });

}]);
