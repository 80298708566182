setupRecipientUploader = function($scope, $mdDialog) {

  $scope.uploadCsv = function () {
      $('[name="file"]').click();
  };

  $('[name="file"]').change(function (e) {
      if (!e.target.value) return;

      var fname = e.target.value;
      var extension = fname.substr((~-fname.lastIndexOf(".") >>> 0) + 2);

      if (extension !== 'csv') {
          var error = $mdDialog.alert()
              .title('That doesn\'t look right...')
              .htmlContent('Sorry! You can only upload CSV files.')
              .ariaLabel('error occurred')
              .ok('OK');
          $mdDialog.show(error);
          return;
      }

      var fileList = document.querySelector('[name="file"]').files;
      var file = fileList[0];

      var fileReader = new FileReader();
      fileReader.onload = function (e) {
          $('[name="csv_data"]').val(e.target.result);

          var confirm = $mdDialog.confirm()
              .title('Upload CSV File?')
              .htmlContent('If you upload a CSV file to configure your zones and recipients,<br/> it will <strong>replace</strong> all of the existing recipients.<br/><br/>Are you sure you want to continue?')
              .ariaLabel('upload')
              .ok('Yes, continue')
              .cancel('Cancel');

          $mdDialog.show(confirm).then(function () {
              $('[name="csvUploadForm"]').submit();
          }, function() {
            $('[name="file"]').val('');
          });
      };
      fileReader.onerror = function (e) {
          console.log('error:', e);
      };
      fileReader.readAsText(file);
  });
}
