app.controller('AdminDeviceController', ['$scope', '$log', '$mdDialog', '$http', function ($scope, $log, $mdDialog, $http) {
    $log.debug('Loading AdminDeviceController');

    $scope.createDevice = function () {
        window.location.href = '/admin/device'
    };

    $scope.uploadDeviceList = function () {
        $('[name="file"]').click();
    };

    setupDeviceUploader($scope);

    $scope.editDevice = function (deviceId) {
        window.location.href = '/admin/device/' + deviceId + '?back=' + encodeURIComponent(window.location.href);
    };

    $scope.clearQuery = function (userId) {
        window.location.href = window.location.pathname;
    };

    function handleSubmission(method, url, fn) {
        var error = $mdDialog.alert()
            .title('This is embarrassing!')
            .htmlContent('Sorry! An error occurred. Please try again in a few minutes.<br/>If the error continues, please contact support.')
            .ariaLabel('error occurred')
            .ok('OK');

        $http({
            method: method,
            url: url
        }).then(function successCallback(response) {
            if (fn && typeof fn === 'function') {
                fn.call(null);
            } else if (fn === true) {
                document.location.reload(true);
            }
        }, function errorCallback(response) {
            $mdDialog.show(error);
        });
    }

    $scope.deleteDevice = function (deviceId) {
        var confirm = $mdDialog.confirm()
            .title('Delete device?')
            .htmlContent('This will permanently delete this device. Are you sure?')
            .ariaLabel('delete device')
            .ok('Yes, delete forever')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
            handleSubmission('DELETE', '/admin/device/' + deviceId, true);
        });
    };

    $scope.submitUser = function () {
        if ($scope.userForm.$pristine || !$scope.userForm.$valid) {
            return;
        }
        $('[name="deviceSearch"]').submit();
    };

    Mousetrap($('[name="deviceSearch"]')[0]).bind('enter', function () {
        $scope.submit();
    });

    Mousetrap($('[name="deviceSearch"]')[0]).bind('enter', function () {
        $('[name="deviceSearch"]').submit();
    });
}]);
